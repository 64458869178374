.styleButton {
    border: 0;
    box-shadow: unset;
    &:disabled {
        background-color: unset;
        cursor: unset;
        &:hover {
        background-color: unset;
        }
    }
    &:focus {
        color: unset;
    }
}
  

.stylePagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    font-size: 16px;
        > div:not(:last-child) {
            padding-right: 16px;
        }
    }

.styleSelect {
    > div {
        border-radius: 6px !important;
    }
}
  

.stylePaginationMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    font-size: 16px;
    > div:not(:last-child) {
        padding-right: 16px;
    }
    .stylePaginationBottom {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }   
    .styleButton {
        display: flex;
        justify-content: flex-end;
    }
}

   

    .styleSelect {
        > div {
            border-radius: 6px !important;
        }
    }
  