@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
.MuiTableCell-root {
    font-family: 'Montserrat',
}
html, body {
  font-family: Montserrat;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  background-color: #E5E5E5;
}

.style_styleButton__3xVOp{border:0;box-shadow:unset}.style_styleButton__3xVOp:disabled{background-color:unset;cursor:unset}.style_styleButton__3xVOp:disabled:hover{background-color:unset}.style_styleButton__3xVOp:focus{color:unset}.style_stylePagination__1APmW{display:flex;justify-content:center;align-items:center;padding:16px 0;font-size:16px}.style_stylePagination__1APmW>div:not(:last-child){padding-right:16px}.style_styleSelect__2r5LP>div{border-radius:6px !important}.style_stylePaginationMobile__2DMJz{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:16px 0;font-size:16px}.style_stylePaginationMobile__2DMJz>div:not(:last-child){padding-right:16px}.style_stylePaginationMobile__2DMJz .style_stylePaginationBottom__1f7Nl{display:flex;justify-content:flex-end;align-items:center}.style_stylePaginationMobile__2DMJz .style_styleButton__3xVOp{display:flex;justify-content:flex-end}.style_styleSelect__2r5LP>div{border-radius:6px !important}
